import axios from "axios";

export const convertRoleType = (name) => {
  switch (name) {
    case "administrators":
      return "Administrator";
    case "managers":
      return "Manager";
    case "staff":
      return "Staff";
    default:
      return;
  }
};

export const convertToDayJsWeekNumber = (name) => {
  switch (name) {
    case 0:
      return "sunday";
    case 1:
      return "monday";
    case 2:
      return "tuesday";
    case 3:
      return "wednesday";
    case 4:
      return "thursday";
    case 5:
      return "friday";
    case 6:
      return "saturday";
    default:
      return;
  }
};

export const convertMonthNumberToName = (name) => {
  switch (name) {
    case 1:
      return "Jan";
    case 2:
      return "Feb";
    case 3:
      return "Mar";
    case 4:
      return "Apr";
    case 5:
      return "May";
    case 6:
      return "Jun";
    case 7:
      return "Jul";
    case 8:
      return "Aug";
    case 9:
      return "Sep";
    case 10:
      return "Oct";
    case 11:
      return "Nov";
    case 12:
      return "Dec";
    default:
      return;
  }
};

export const scoreToMessage = (name) => {
  switch (name) {
    case 1:
    case 2:
    case 3:
    case 4:
      return "Looking a bit low!";
    case 5:
    case 6:
    case 7:
      return "Not bad!";
    case 8:
    case 9:
    case 10:
      return "Looks Great!";
    default:
      return;
  }
};

export const uppercaseFirstLetter = (str) => {
  if (str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  return str;
};

export const bookingInterpreter = (anon, name) => {
  if (anon) {
    return "Anonymous Booking";
  }
  return name;
};

export const determineColourFromScoreValue = (value, max = 10) => {
  value = (value / max) * 100;

  if (value > 70) {
    return "#66D64E";
  }

  if (value < 80 && value > 30) {
    return "#F1AC24";
  }
  if (value < 40) {
    return "#E45D24";
  }
  return;
};
