import { Box, Typography } from "@mui/material";
import { summaryBarGroupStyles } from "./dashboardComponentStyles";
import SummaryBar from "./SummaryBar";

const SummaryBarGroup = (props) => {
  const classes = summaryBarGroupStyles();
  const { groupTitle, valuesBlue = 0, valuesGreen = 0 } = props;
  return (
    <Box className={classes.outerContainer}>
      <Typography variant="h3">{groupTitle}</Typography>
      <SummaryBar name={"This Week"} value={valuesGreen} />
      <SummaryBar name={"Last Week"} value={valuesBlue} colour={"#6AB1FF"} />
    </Box>
  );
};

export default SummaryBarGroup;
