import { Box, Typography } from "@mui/material";
import { bookingsList } from "./bookingsComponentStyles";
import * as isBetween from "dayjs/plugin/isBetween";
import { useAuth0 } from "@auth0/auth0-react";
import { getBookings } from "../../apiHelper";
import { useState, useEffect } from "react";
import dayjs from "dayjs";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import BookingListItem from "./BookingListItem";
import { NoBookingsComponent } from "./NoBookingsComponent";

dayjs.extend(isBetween);

const ConfirmedBookingsList = (props) => {
  const classes = bookingsList();
  const { user, getAccessTokenSilently } = useAuth0();
  const [confirmedBookings, setConfirmedBookings] = useState([]);
  const {} = props;
  const [date, changeDate] = useState(dayjs(new Date()));
  useEffect(() => {
    getAccessTokenSilently().then((t) => {
      getBookings(t, {
        selectedDate: date,
        confirmed: true,
      }).then((cb) => {
        setConfirmedBookings(cb.bookings);
      });
    });
  }, [date]);

  return (
    <Box className={classes.outerContainer}>
      <Box className={classes.subtitleRow}>
        <Typography variant="h2">Confirmed</Typography>
      </Box>
      <Box className={classes.subtitleRow}>
        <Box>
          <StaticDatePicker
            onChange={(c) => {
              changeDate(c);
            }}
            value={date}
            componentsProps={{
              actionBar: {
                actions: [],
              },
            }}
          />
        </Box>
        <Box className={classes.confirmedBookingsContainer}>
          {confirmedBookings && confirmedBookings.length > 0 ? (
            confirmedBookings.map((booking, i) => (
              <>
                <BookingListItem key={i} booking={booking} />
              </>
            ))
          ) : (
            <NoBookingsComponent />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default ConfirmedBookingsList;
