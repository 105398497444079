import { Box, Typography, Modal } from "@mui/material";
import { bookingListItem } from "./bookingsComponentStyles";
import dayjs from "dayjs";
import { bookingInterpreter } from "../../helperFunctions";
import StarIcon from "@mui/icons-material/Star";
import CircleIcon from "@mui/icons-material/Circle";
import { Button } from "antd";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { confirmBooking } from "../../apiHelper";
import { useAuth0 } from "@auth0/auth0-react";
import { useState } from "react";
// import RescheduleModal from "./RescheduleModal";
import CancelModal from "./CancelModal";

const BookingListItem = (props) => {
  const classes = bookingListItem();
  const { booking, unconfirmed, bookings, setBookings } = props;
  const { getAccessTokenSilently } = useAuth0();

  // const [rescheduleModal, openRescheduleModal] = useState(false);
  const [cancelModal, openCancelModal] = useState(false);

  const handleConfirmation = () => {
    getAccessTokenSilently().then((t) => {
      confirmBooking(t, {
        bookingId: booking._id,
        recipientName: booking.bookingMakerId
          ? booking.bookingMakerId.firstName
          : null,
        recipientEmail: booking.bookingMakerId
          ? booking.bookingMakerId.email
          : null,
        dateTime: `${dayjs(booking.bookingDate).format(
          "DD MMMM YYYY"
        )}, ${dayjs(booking.bookingTime, "H:mm").format("h:mm a")}`,
      }).then((nb) => {
        setBookings((prev) => {
          return prev.map((ob) => {
            if (ob._id === booking._id) {
              return {
                ...ob,
                confirmed: nb.confirmedBookingData.confirmed,
              };
            } else {
              return ob;
            }
          });
        });
      });
    });
  };

  return (
    <Box className={unconfirmed ? classes.unconfirmedOuter : classes.outer}>
      <Box className={classes.contentContainer}>
        <Box className={classes.dateTimeRow}>
          <Box className={classes.date}>
            <Typography variant="h2">
              {bookingInterpreter(
                booking.anonymous,
                `${booking.bookingMakerId.firstName} ${booking.bookingMakerId.lastName}`
              )}
            </Typography>
          </Box>
        </Box>
        <Box className={classes.date}>
          {" "}
          <Typography variant="h3">
            {dayjs(booking.bookingDate).format("DD MMMM YYYY")} at{" "}
            {dayjs(booking.bookingTime, "H:mm").format("h:mm a")}
          </Typography>
        </Box>
        {booking.somethingElse ? (
          <Typography variant="body1">General booking</Typography>
        ) : (
          <Box className={classes.answerInfo}>
            <StarIcon fontSize="inherit" sx={{ width: "1em", height: "1em" }} />
            <Typography variant="body1">{booking.score}</Typography>
            <Box className={classes.dotContainer}>
              <CircleIcon
                fontSize="inherit"
                sx={{ width: "0.4em", height: "0.4em" }}
              />
            </Box>
            <Typography variant="body1">
              {booking.questionId ? booking.questionId.question : null}
            </Typography>
          </Box>
        )}
        <Box className={classes.comments}>
          <Typography variant="body1">{booking.comments}</Typography>
        </Box>
      </Box>
      <Box className={classes.buttonsContainer}>
        <Box className={classes.buttonOuter}>
          {booking.confirmed && (
            <Box className={classes.confirmedContainer}>
              <CheckCircleOutlineIcon />
              <Typography>Confirmed</Typography>
            </Box>
          )}
        </Box>
        <Box className={classes.buttonGroupRight}>
          <Box className={classes.buttonOuter}>
            {/* <Button
              sx={{ textTransform: "none" }}
              color="primary"
              onClick={() => openRescheduleModal(true)}
            >
              <Typography variant="body2">
                {unconfirmed ? `Reschedule and Confirm` : "Reschedule"}
              </Typography>
            </Button> */}
            {/* <RescheduleModal
              bookingId={booking._id}
              bookingMakerName={booking.bookingMakerId.firstName}
              recipientEmail={booking.bookingMakerId.email}
              modalOpen={rescheduleModal}
              openModal={openRescheduleModal}
              bookings={bookings}
              setBookings={setBookings}
            /> */}
            {!booking.confirmed && (
              <Button
                sx={{ textTransform: "none" }}
                color="primary"
                onClick={() => {
                  handleConfirmation();
                }}
              >
                <Typography variant="body2">Accept</Typography>
              </Button>
            )}
          </Box>
          <Box className={classes.buttonOuter}>
            <Button
              sx={{ textTransform: "none" }}
              color="primary"
              onClick={() => openCancelModal(true)}
            >
              <Typography variant="body2">Cancel</Typography>
            </Button>
            <CancelModal
              bookingId={booking._id}
              bookingMakerName={booking.bookingMakerId.firstName}
              recipientEmail={booking.bookingMakerId.email}
              modalOpen={cancelModal}
              openModal={openCancelModal}
              bookings={bookings}
              setBookings={setBookings}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default BookingListItem;
