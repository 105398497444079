import { Avatar, Box, Button, Modal, Typography } from "@mui/material";
import { responsesLogItemStyles } from "./dashboardComponentStyles";
import StarIcon from "@mui/icons-material/Star";
import dayjs from "dayjs";
import CircleIcon from "@mui/icons-material/Circle";
import { useState } from "react";

const ResponsesLogItem = (props) => {
  const classes = responsesLogItemStyles();
  const { response } = props;
  const [modalOpen, setModalOpen] = useState(false);
  return (
    <>
      <Box
        className={classes.outerContainer}
        onClick={() => setModalOpen(true)}
      >
        <Box className={classes.avatarContainer}>
          <Avatar
            sx={{
              lineHeight: 0,
            }}
          >
            {response.anonymous
              ? "AP"
              : `${response.personId.firstName[0]}${response.personId.lastName[0]}`}
          </Avatar>
        </Box>
        <Box className={classes.details}>
          <Typography>
            {response.anonymous
              ? "Anonymous Person"
              : `${response.personId.firstName} ${response.personId.lastName}`}
          </Typography>
          <Box className={classes.nameLine}>
            <Box className={classes.scoreBox}>
              <StarIcon
                sx={{
                  fontSize: 14,
                  fontWeight: 600,
                  paddingBottom: 0.5,
                  color: "#f9f901",
                }}
              />
              <Typography fontWeight={600} fontSize={14}>
                {response.score ? `${response.score}` : ""}
              </Typography>
            </Box>
            <CircleIcon
              sx={{
                fontSize: 6,
                marginBottom: "0.2rem",
                marginLeft: "0.4rem",
                marginRight: "0.4rem",
                color: "#d6d6d6",
              }}
            />
            <Typography fontWeight={400} fontSize={14}>
              {dayjs(response.createdAt).format("D MMM YYYY")}
            </Typography>
          </Box>
          {response.value ? (
            <Typography
              sx={{
                display: "-webkit-box",
                overflow: "hidden",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 3,
                fontSize: 16,
                fontWeight: 400,
                fontStyle: "italic",
                color: "#4f4f4f",
              }}
            >
              "{response.value}"
            </Typography>
          ) : null}
        </Box>
      </Box>
      {modalOpen && (
        <Modal
          open={modalOpen}
          onClose={(e) => {
            e.stopPropagation();
            setModalOpen(false);
          }}
        >
          <Box className={classes.modalBase}>
            <Typography sx={{ fontWeight: 400, fontStyle: "italic" }}>
              "{response.value}"
            </Typography>
            <Box className={classes.buttonRow}>
              <Button onClick={() => setModalOpen(false)}>Close</Button>
            </Box>
          </Box>
        </Modal>
      )}
    </>
  );
};

export default ResponsesLogItem;
