import { hrOverviewStyles } from "./pagesStyles";
import { Box } from "@mui/material";
import { Outlet, useNavigate } from "react-router-dom";
import NavBar from "../hrDashboardComponents/NavBar";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { getProfile } from "../apiHelper";

function HrOverview() {
  const classes = hrOverviewStyles();
  const history = useNavigate();
  const { user, getAccessTokenSilently, logout } = useAuth0();

  const [profile, setProfile] = useState({ role: "staff" });
  useEffect(() => {
    if (user) {
      getAccessTokenSilently().then((t) => {
        getProfile(t, { externalId: user.sub })
          .then((pr) => {
            if (pr.profileUser.role === "staff") {
              history("/");
            } else {
              setProfile(pr.profileUser);
            }
          })
          .catch((e) => {
            console.log(e);
            logout({
              logoutParams: {
                returnTo:
                  process.env.REACT_APP_REDIRECT_URL ||
                  "http://localhost:3000/",
              },
            });
          });
      });
    }
  }, [user]);
  return (
    <Box className={classes.hrOverviewContainerOuter}>
      <NavBar profile={profile} />
      <Box className={classes.outletContainer}>
        <Outlet context={{ profile }} />
      </Box>
    </Box>
  );
}

export default HrOverview;
