import { Box, Typography } from "@mui/material";
import ResponsesLogItem from "./ResponsesLogItem";
import { responsesLogStyles } from "./dashboardComponentStyles";
import { getMoreLatestResponses } from "../../apiHelper";
import { useAuth0 } from "@auth0/auth0-react";

const ResponsesLog = (props) => {
  const classes = responsesLogStyles();
  const { data, setLatestResponses } = props;
  const { getAccessTokenSilently } = useAuth0();

  const handleScroll = (e) => {
    const { scrollTop, clientHeight, scrollHeight } = e.currentTarget;

    if (scrollHeight - scrollTop <= clientHeight) {
      if (data.hasNextPage) {
        getAccessTokenSilently().then((t) =>
          getMoreLatestResponses(t, { page: data.nextPage }).then(
            (newResponse) => {
              setLatestResponses({
                ...newResponse.recentAnswers,
                docs: [...data.docs, ...newResponse.recentAnswers?.docs],
              });
            }
          )
        );
      }
    }
  };
  return (
    <Box className={classes.outerContainer}>
      <Typography variant="h3">Latest Responses</Typography>
      <Box className={classes.innerContainer} onScroll={handleScroll}>
        {data &&
          data.docs?.map((response) => (
            <ResponsesLogItem response={response} />
          ))}
      </Box>
    </Box>
  );
};

export default ResponsesLog;
