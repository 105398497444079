import { bookingsPageStyles } from "./pagesStyles";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import { useState } from "react";
import BookingsList from "../hrDashboardComponents/bookingsComponents/BookingsList";
import BookingsSettings from "../hrDashboardComponents/bookingsComponents/BookingsSettings";
import ConfirmedBookingsList from "../hrDashboardComponents/bookingsComponents/ConfirmedBookings";

function Bookings() {
  const classes = bookingsPageStyles();

  const [tab, setTab] = useState("new");

  const determineTab = (selectedTab) => {
    switch (selectedTab) {
      case "new":
        return <BookingsList />;
      case "confirmed":
        return <ConfirmedBookingsList />;
      case "settings":
        return <BookingsSettings />;
    }
  };

  return (
    <Box className={classes.outerContainer}>
      <Typography variant="h1">Bookings</Typography>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={tab} onChange={(e, v) => setTab(v)}>
          <Tab value="new" label="New" />
          <Tab value="confirmed" label="Confirmed" />
          <Tab value="settings" label="Settings" />
        </Tabs>
      </Box>
      {determineTab(tab)}
    </Box>
  );
}

export default Bookings;
