import { Box, Typography } from "@mui/material";
import { bookingsList } from "./bookingsComponentStyles";

import * as isBetween from "dayjs/plugin/isBetween";
import { useAuth0 } from "@auth0/auth0-react";
import { getBookings } from "../../apiHelper";
import { useState, useEffect } from "react";
import dayjs from "dayjs";
import BookingListItem from "./BookingListItem";
import { styled } from "@mui/material/styles";
import { NoBookingsComponent } from "./NoBookingsComponent";
import Badge from "@mui/material/Badge";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

dayjs.extend(isBetween);

const BookingsList = (props) => {
  const classes = bookingsList();
  const { user, getAccessTokenSilently } = useAuth0();
  const [bookings, setBookings] = useState([]);
  const [confirmedBookings, setConfirmedBookings] = useState([]);
  const {} = props;
  const [date, changeDate] = useState(dayjs(new Date()));
  const [unconfirmedOpen, setUnconfirmedOpen] = useState(true);

  useEffect(() => {
    getAccessTokenSilently().then((t) => {
      getBookings(t, { confirmed: false }).then((b) => {
        setBookings(b.bookings);
      });
    });
  }, []);
  useEffect(() => {
    getAccessTokenSilently().then((t) => {
      getBookings(t, {
        selectedDate: date,
        confirmed: true,
      }).then((cb) => {
        setConfirmedBookings(cb.bookings);
      });
    });
  }, [date]);

  const StyledBadge = styled(Badge)({
    "& .MuiBadge-badge": {
      right: -18,
      top: 12,
    },
  });

  return (
    <Box className={classes.outerContainer}>
      <Box className={classes.subtitleRow}>
        <Typography variant="h2">New</Typography>
        <StyledBadge
          badgeContent={bookings.length}
          color="primary"
        ></StyledBadge>
        <Box className={classes.hideArrowContainer}>
          {unconfirmedOpen ? (
            <KeyboardArrowDownIcon
              onClick={() => setUnconfirmedOpen(!unconfirmedOpen)}
            />
          ) : (
            <KeyboardArrowUpIcon
              onClick={() => setUnconfirmedOpen(!unconfirmedOpen)}
            />
          )}
        </Box>
      </Box>
      {unconfirmedOpen ? (
        <Box className={classes.unconfirmedRow}>
          {bookings && bookings.length > 0 ? (
            bookings.map((booking, i) => (
              <Box className={classes.bookingListItemOuter} key={i}>
                <BookingListItem
                  booking={booking}
                  bookings={bookings}
                  setBookings={setBookings}
                  unconfirmed={true}
                />
              </Box>
            ))
          ) : (
            <NoBookingsComponent />
          )}
        </Box>
      ) : null}
    </Box>
  );
};

export default BookingsList;
