import { helpContainerComponentStyles } from "./componentsStyles";
import { Box, Typography } from "@mui/material";
import dayjs from "dayjs";

const AvailableTimesComponent = (props) => {
  const classes = helpContainerComponentStyles();
  const {
    slot,
    selectedSlot,
    changeSelectedSlot,
    bookingData,
    changeBookingData,
  } = props;
  return (
    <Box
      className={
        slot.time === selectedSlot
          ? classes.appointmentSelected
          : classes.appointment
      }
      onClick={() => {
        changeBookingData({
          ...bookingData,
          bookingTime: slot.time,
          chatProviderUserId: slot.provider,
        });
        changeSelectedSlot(slot.time);
      }}
    >
      <Typography variant="h2">
        {dayjs(slot.time, "H:mm").format("h:mm a")}
      </Typography>
      <Typography variant="body1">15 minutes</Typography>
    </Box>
  );
};

export default AvailableTimesComponent;
