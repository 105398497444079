import { Box, Typography } from "@mui/material";
import { Line } from "react-chartjs-2";
import { summaryLineStyles } from "./dashboardComponentStyles";

const SummaryLine = (props) => {
  const { type, title, dataGreen, dataBlue, labels, max = 10 } = props;
  const classes = summaryLineStyles();
  const data = {
    labels: labels,
    datasets: [
      {
        label: "First dataset",
        data: dataGreen,
        fill: true,
        backgroundColor: "rgba(167, 196, 161,0.4)",
        borderColor: "#A7C4A1",
      },
      {
        label: "Second dataset",
        data: dataBlue,
        borderColor: "#017AFE",
        fill: true,
        backgroundColor: "rgba(1, 122, 254,0.1)",
      },
    ],
  };

  return (
    <Box
      className={
        type === "grow" ? classes.outerContainer : classes.staticOuterContainer
      }
    >
      <Typography className={classes.title}>{title}</Typography>
      <Line
        data={data}
        options={{
          maintainAspectRatio: false,
          plugins: {
            legend: false,
            tooltip: {
              enabled: false,
            },
          },
          scales: {
            x: {
              grid: {
                display: false,
              },
            },
            y: {
              min: 0,
              max: max,
              grid: {
                display: false,
              },
            },
          },
        }}
      />
    </Box>
  );
};

export default SummaryLine;
