import { peoplePageStyles } from "./pagesStyles";
import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { getProfile } from "../apiHelper";
import { useAuth0 } from "@auth0/auth0-react";
import ProfileUserDetails from "../hrDashboardComponents/profileComponents/ProfileUserDetails";

function Profile() {
  const classes = peoplePageStyles();
  const { user, getAccessTokenSilently, logout } = useAuth0();

  const [profileInfo, setProfileInfo] = useState({});

  useEffect(() => {
    if (user) {
      getAccessTokenSilently().then((t) => {
        getProfile(t, { externalId: user.sub })
          .then((p) => {
            setProfileInfo(p.profileUser);
          })
          .catch((e) => {
            console.log(e);
            logout({
              logoutParams: {
                returnTo:
                  process.env.REACT_APP_REDIRECT_URL ||
                  "http://localhost:3000/",
              },
            });
          });
      });
    }
  }, [user]);

  return (
    <Box className={classes.outerContainer}>
      <Typography variant="h1">Profile</Typography>
      <ProfileUserDetails userDetails={profileInfo} logout={logout} />
    </Box>
  );
}

export default Profile;
